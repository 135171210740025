import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['message']

  messageTargetConnected(elem) {
    elem.addEventListener('animationend', (e) => {
      elem.remove()
    })

    elem.classList.add('fade-out')
  }

}
import { Controller } from '@hotwired/stimulus'
import { get } from 'lodash-es'

export default class extends Controller {
  static targets = ['form', 'data']
  static values = { origin: String }

  connect() {
    this.setupListener()
  }

  setupListener() {
    window.addEventListener('message', event => {
      if (event.origin === this.originValue) {
        if (!this.hasDataTarget) {
          console.error('unable to submit lesson data - no data target')
          return false
        }
        const data = get(event, 'data', {})
        console.log('Message received from iframe:', event.data)
        this.dataTarget.value = JSON.stringify(event.data)

        if (!this.hasFormTarget) {
          console.error('unable to submit lesson data - no form target')
          return false
        }
        this.formTarget.requestSubmit()
      }
    })
  }
}